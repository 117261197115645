:root {
    --primary-color: #007bff;
    --background-color: #000;
    --text-color: #ccc;
    --border-color: #404040;
    --error-color: #ff4d4f;
}

.contactFormSection {
    text-align: center;
    padding: 50px 20px 150px 20px;
    color: var(--text-color);
    scroll-snap-align: start;
}

.formTitle {
    font-size: 32px;
    font-weight: bold;
    color: white;
    margin-bottom: 10px;
}

.formSubtitle {
    margin-bottom: 30px;
    font-size: 16px;
    color: #aaa;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.label {
    width: 100%;
    max-width: 500px;
    text-align: left;
    margin-bottom: 15px;
    color: var(--text-color);
}

.inputField,
.textArea {
    width: 100%;
    padding: 15px;
    margin-top: 5px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    background-color: var(--background-color);
    color: #ddd;
    font-size: 16px;
}

.textArea {
    height: 200px;
}

.errorText {
    color: var(--error-color);
    font-size: 12px;
    margin-top: 5px;
}

.checkboxes {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    max-width: 500px;
    margin: 20px 0;
}

.checkboxGroup {
    display: flex;
    flex-direction: column;
    margin-right: 18px;
}

.checkboxLabel {
    position: relative;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    color: var(--text-color);
    cursor: pointer;
}

.checkboxLabel input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    left: 0;
    margin: 0;
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.checkboxLabel .customCheckbox {
    width: 18px;
    height: 18px;
    background-color: var(--background-color);
    border: 2px solid #0C6CDC;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    margin-right: 10px;
    flex-shrink: 0;
}

.checkboxLabel input[type="checkbox"]:focus + .customCheckbox {
    outline: 2px solid #0C6CDC;
}

.checkboxLabel input[type="checkbox"]:checked + .customCheckbox::after {
    content: '';
    position: absolute;
    left: 4px;
    top: 0;
    width: 5px;
    height: 10px;
    border: solid #0C6CDC;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.checkboxLabel input[type="checkbox"]:disabled + .customCheckbox {
    opacity: 0.6;
}

.link {
    color: var(--primary-color);
    text-decoration: none;
}

.link:hover {
    color: #0056b3;
}

.submitButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 15px 40px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    width: 100%;
    max-width: 500px;
}

.submitButton:hover {
    background-color: #0056b3;
}

.buttonIcon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    fill: currentColor;
}

@media (max-width: 600px) {
    .formTitle {
        font-size: 28px;
    }

    .formSubtitle {
        font-size: 14px;
    }

    .inputField,
    .textArea,
    .submitButton {
        font-size: 16px;
    }

    .checkboxLabel {
        font-size: 13px;
    }

    .submitButton {
        padding: 12px 30px;
    }
}
