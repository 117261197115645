:root {
    --primary-color: #007bff;
    --background-color: #000;
    --text-color: #ccc;
    --border-color: #555;
}

*, *::before, *::after {
    box-sizing: border-box;
}

.row {
    max-width: 1280px;
    margin: 10px auto;

}

.miniHeaderContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    scroll-snap-align: start;
}

.companyLogo{
    width: 200px;
}

