.headerSection {
    position: relative;
    width: 100%;
    height: 80vh;
    overflow: hidden;
    touch-action: pan-y;
    scroll-snap-align: start;
}

.canvasContainer {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    touch-action: pan-y;
}

.logoContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.companyLogo {
    width: 600px;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
}

.scrollDownIcon {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: auto;
    z-index: 1;
    opacity: 0;
    animation: fadeIn 0.8s ease-out forwards, bounce 2s infinite 1s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-15px);
    }
    60% {
        transform: translateY(-7px);
    }
}

@media (max-width: 768px) {
    .companyLogo {
        width: 300px;
        max-width: 80%;
    }

    .scrollDownIcon {
        bottom: 15px;
        width: 30px;
    }
}

@media (max-width: 480px) {
    .logoContainer {
        width: 85vw;
    }

    .companyLogo {
        width: 100%;
        max-width: 100%;
    }

    .scrollDownIcon {
        bottom: 10px;
        width: 25px;
    }
}
