.contactPage {
    background-color: #000;
    color: #fff;
    display: flex;
    flex-direction: column;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    min-height: 500px;
}

.row {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 20px;
}