:root {
    --primary-color: #007bff;
    --background-color: #000;
    --text-color: #ccc;
    --border-color: #555;
}

*, *::before, *::after {
    box-sizing: border-box;
}

.footerContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    scroll-snap-align: start;
}

.footerGlow {
    position: absolute;
    top: -100px;
    left: 50%;
    width: 100%;
    height: 150px;
    background: radial-gradient(
            circle,
            rgba(0, 123, 255, 0.2) 20%,
            rgba(0, 123, 255, 0.1) 50%,
            transparent 100%
    );
    transform: translateX(-50%);
    filter: blur(30px);
    z-index: 1;
    pointer-events: none;
}

.footerSection {
    background-color: var(--background-color);
    color: var(--text-color);
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 2;
    margin: 0;
}

.footerTop {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 40px;
    flex-wrap: wrap;
    justify-content: space-around;
}

.logoAndSubscription {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.companyLogo {
    width: 150px;
    margin-bottom: 20px;
}

.newsletterTitle {
    font-size: 18px;
    color: #fff;
    margin-bottom: 15px;
}

.subscriptionForm {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.subscriptionInput {
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    background-color: var(--background-color);
    color: var(--text-color);
    width: 300px;
    max-width: 100%;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1;
}

.subscriptionButton {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background-color: var(--primary-color);
    color: white;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 10px;
}

.subscriptionButton:hover {
    background-color: #0056b3;
}

.buttonIcon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    fill: currentColor;
}

.privacyText {
    font-size: 12px;
    color: #aaa;
}

.footerLinks {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 50px;
    align-content: flex-end;
    flex-direction: column;
}

.link {
    color: var(--text-color);
    text-decoration: underline;
    font-size: 14px;
}

.link:hover {
    color: white;
}

.footerBottom {
    padding-top: 20px;
    text-align: center;
    width: 100%;
}

.centeredText {
    color: var(--text-color);
    font-size: 14px;
}

.inlineLogo {
    width: 50px;
    display: inline-block;
    vertical-align: middle;
}

@media (max-width: 768px) {
    .footerTop {
        flex-direction: column;
        align-items: flex-start;
    }

    .companyLogo {
        width: 120px;
        margin-bottom: 15px;
    }

    .subscriptionForm {
        flex-direction: column;
        align-items: stretch;
    }

    .subscriptionInput,
    .subscriptionButton {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .footerLinks {
        justify-content: flex-start;
        gap: 10px;
        margin-top: 38px;

        .link {
            margin-top: 12px;
        }
    }

    .footerBottom {
        padding-top: 10px;
    }

    .centeredText {
        font-size: 12px;
    }

    .inlineLogo {
        width: 40px;
    }
}
